import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Dolan from "../../Assets/Projects/dolan-image.png";
import Bot from "../../Assets/Projects/Que-es-un-bot.jpg.webp";

const data = [
  {
    image: Dolan,
    blog: false,
    title: "Dolan Project",
    description:
      "This web is made for programming assignments on campus made using next js.",
    gitlink: null,
    demo: "https://dolanprojek.vercel.app/",
  },
  {
    image: Bot,
    blog: false,
    title: "Bot Telegram",
    description:
      "I created this telegram bot to make it easier for Cahaya Solusindo company employees to check clients",
    gitlink: null,
    demo: null,
  },
];

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {data.map((item, idx) => (
            <Col md={4} className="project-card">
              <ProjectCard
                key={idx}
                imgPath={item.image}
                isBlog={item.blog}
                title={item.title}
                description={item.description}
                ghLink={item.gitlink}
                demoLink={item.demo}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
