import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import Logo from "./../../Assets/myavatar.png";
import { Link } from "react-router-dom";
export default function Blog() {
  const [blog, setBlog] = useState([]);

  const FetchBlog = async () => {
    const data = await axios.get(
      // `https://www.googleapis.com/blogger/v3/blogs/${process.env.BLOG_ID}?key=${process.env.API_KEY}`
      "https://www.googleapis.com/blogger/v3/blogs/458815202628794835/posts?fetchImages=true&key=AIzaSyDEtls1BAa7_PrgKum6wF_pShDUr-ED4G4"
    );
    setBlog(data.data.items);
  };

  let slug = [];
  let dt = blog.map((i) => i.title);

  for (let i = 0; i < dt.length; i++) {
    const element = JSON.stringify(dt[i]).split(" ").join("-");
    const unstring = JSON.parse(element);
    slug.push(unstring);
  }

  console.log(slug);

  useEffect(() => {
    FetchBlog();
  }, []);

  return (
    <Container>
      <div className="mt-5 py-4">
        <div className="row justify-content-center align-items-center g-2">
          {blog.map((item, i) => {
            return (
              <div className="col-lg-4" key={i}>
                <Card style={{ height: "24em" }}>
                  <Card.Img
                    width={30}
                    variant="top"
                    className="blog-img"
                    src={item.images[0].url}
                    alt={item.title}
                  />
                  <Card.Body className="text-start">
                    <Button className="btn-sm mb-2">Category</Button>
                    <div style={{ height: "35px" }}>
                      <Link
                        to={`/blog/${item.id}`}
                        className="link-dark "
                        style={{ textDecoration: "none" }}
                      >
                        <Card.Title
                          className="fw-bold "
                          style={{ fontSize: "18px" }}
                        >
                          {item.title}
                        </Card.Title>
                      </Link>
                    </div>
                    <hr className="" />

                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={Logo}
                        className="rounded-pill"
                        style={{ width: "30px" }}
                      />
                      <span>Ahmad Nurul Musthopa</span>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}
