import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
export const DetailBlog = () => {
  const { id } = useParams();

  const [blogdetail, setBlogdetail] = useState([]);

  const FetchDetailBlog = async () => {
    const data = await axios.get(
      `https://www.googleapis.com/blogger/v3/blogs/458815202628794835/posts/${id}?fetchImages=true&key=AIzaSyDEtls1BAa7_PrgKum6wF_pShDUr-ED4G4`
    );
    console.log(data.data);

    setBlogdetail(data.data);
  };

  useEffect(() => {
    FetchDetailBlog();
    return () => {};
  }, []);

  return (
    <Container>
      <div className="detail-blog">
        <p
          className="text-white"
          dangerouslySetInnerHTML={{ __html: blogdetail.content }}
        />
      </div>
      {/* {blogdetail.map((item) => {
        <p>{item.content}</p>;
      })} */}
    </Container>
  );
};
